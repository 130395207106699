import { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';

import { ActionModal } from 'shared/components/Modal';

import { oneMb } from './constants';
import { SelectFile } from './components/SelectFile';
import { UploadFileModalProps } from './types';
import { UploadedFileSummary } from './components/UploadedFileSummary';
import styles from './UploadFileModal.module.css';

const UploadFileModal = ({
  acceptedFileTypes,
  getCustomUploadUI,
  isOpen,
  onClose,
  onDownloadCustomResult,
  onDownloadCustomTemplate,
  onDownloadResult,
  onDownloadTemplate,
  onUploadFile,
  uploadFileLoading,
  uploadedFile,
  templates,
  fileType,
}: UploadFileModalProps) => {
  const [file, setFile] = useState<File | null>(null);

  const isDisabledUpload = !file || file.size > oneMb * 10 || uploadFileLoading;

  const handleUploadFile = useCallback(() => onUploadFile(file!), [onUploadFile, file]);

  const { pathname } = useLocation();

  const rowLimit = pathname === '/vendor-cost-management' ? 3000 : 1000;

  // Generate Upload Modal UI
  let useUploadModal = null;

  if (templates?.length) {
    // This section is the newer refactored method of generating the UI for the upload modal
    // if there are multiple templates for the same modal.
    const templateFileType = (fileType ?? 'CSV').toUpperCase();
    useUploadModal = () => (
      <>
        Use <Typography variant="bold1">upload file</Typography> for mass data adding. Download one
        of the following {templateFileType} templates for your use case. Fill in the template, and
        upload it to the app.
        <br />
        <Typography variant="bold1">{templateFileType} Templates:</Typography>
        {templates.map(({ name, downloadCallback }) => (
          <Button
            key={name}
            className={styles.templates}
            component="div"
            onClick={downloadCallback}
          >
            {name}
          </Button>
        ))}
      </>
    );
  } else {
    // DEPRECATED: The code section below is the older method of generating the UI for the
    // upload modal based on hardcoding the templates and pathnames. Unless you need to re-use
    // the default case where you only have 1 template and it is CSV, try not to touch the code.
    // Instead, use method listed in
    // https://gopuff.atlassian.net/wiki/spaces/SCPL/pages/3636723750/UI+Add+Upload+Modal

    const itemCustomTemplateUploadButtonText = [
      'UPDATE ALL ITEM FIELDS',
      'UPDATE ONLY ITEM ASSORTMENT',
      'UPDATE ONLY ITEM STOP BUYING',
    ];

    const itemSourceCustomTemplateUploadButtonText = [
      'CREATE NEW CASEPACK (ALL FIELDS)',
      'UPDATE ONLY SUPPLIER ITEM NUMBER ON EXISTING CASEPACK',
      'UPDATE ONLY UPC ON EXISTING CASEPACK',
      'UPDATE ONLY DIMENSIONS ON EXISTING CASEPACK',
      'UPDATE ONLY PACKAGING QTY ON EXISTING CASEPACK',
      'UPDATE ONLY BREAKABLE FIELDS ON EXISTING ITEM SOURCE',
      'UPDATE ONLY ORDERING FIELDS ON EXISTING ITEM SOURCE',
      'DELETE A CASEPACK',
    ];

    const vendorCostManagementUploadButtonText = [
      'REGULAR COST TEMPLATE',
      'PROMOTIONAL COST TEMPLATE',
    ];

    useUploadModal = () => {
      switch (pathname) {
        case '/item':
          return (
            <>
              Use <Typography variant="bold1">upload file</Typography> for mass data adding.
              Download one of the following CSV templates for your use case. Fill in the template,
              and upload it to the app.
              <br />
              <Typography variant="bold1">CSV Templates:</Typography>
              {itemCustomTemplateUploadButtonText.map(template => (
                <Button
                  key={template}
                  className={styles.templates}
                  component="div"
                  onClick={() => onDownloadCustomTemplate(template)}
                >
                  {template}
                </Button>
              ))}
            </>
          );
        case '/item-source':
          return (
            <>
              Use <Typography variant="bold1">upload file</Typography> for mass data adding.
              Download one of the following CSV templates for your use case. Fill in the template,
              and upload it to the app.
              <br />
              <Typography variant="bold1">CSV Templates:</Typography>
              {itemSourceCustomTemplateUploadButtonText.map(template => (
                <Button
                  key={template}
                  className={styles.templates}
                  component="div"
                  onClick={() => onDownloadCustomTemplate(template)}
                >
                  {template}
                </Button>
              ))}
            </>
          );
        case '/vendor-cost-management': {
          return (
            <>
              Use <Typography variant="bold1">upload file</Typography> for mass data adding.
              Download one of the following Excel templates for your use case. Fill in the template,
              and upload it to the app.
              <br />
              <Typography variant="bold1">Excel Templates:</Typography>
              {vendorCostManagementUploadButtonText.map(template => (
                <Button
                  key={template}
                  className={styles.templates}
                  component="div"
                  onClick={() => onDownloadCustomTemplate(template)}
                >
                  {template}
                </Button>
              ))}
            </>
          );
        }
        default:
          return (
            <>
              Use <Typography variant="bold1">upload file</Typography> for mass data adding.
              Download a simple
              <Button className={styles.link} component="span" onClick={onDownloadTemplate}>
                CSV template
              </Button>
              to see an example of the format required. Fill in the template, and upload it to the
              app.
            </>
          );
      }
    };
  }

  return (
    <ActionModal
      actions={
        <>
          <Button onClick={onClose} variant="outlined">
            {uploadedFile ? 'Close' : 'Cancel'}
          </Button>
          {!uploadedFile && (
            <Button disabled={isDisabledUpload} onClick={handleUploadFile} variant="contained">
              Upload
            </Button>
          )}
        </>
      }
      hideCloseIcon
      isOpen={isOpen}
      onClose={onClose}
      size="small"
      title="Upload File"
    >
      {getCustomUploadUI ? getCustomUploadUI() : useUploadModal()}
      {uploadedFile ? (
        <UploadedFileSummary
          onDownloadCustomResult={onDownloadCustomResult}
          onDownloadResult={onDownloadResult}
          uploadedFile={uploadedFile}
        />
      ) : (
        <SelectFile
          acceptedFileTypes={acceptedFileTypes}
          loading={uploadFileLoading}
          onChangeFile={setFile}
          rowLimit={rowLimit}
        />
      )}
    </ActionModal>
  );
};

export { UploadFileModal };
