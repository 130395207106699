import { O } from 'ts-toolbelt';

import { mergeDeepArrays } from 'shared/utils';
import { Item } from 'shared/types';

import { makeItem } from '../factories';
import { pickOptions, replicateN } from '../utils';

import { manufacturingTypes } from './manufacturingTypes';
import { alcoholTypes } from './alcoholTypes';
import { nationalAssortments } from './nationalAssortments';
import { tobaccoTypes } from './tobaccoTypes';
import { capabilities } from './capabilities';
import { customAssortments } from './customAssortments';

const manufacturingTypeOptions = manufacturingTypes();
const alcoholTypesOptions = alcoholTypes();
const nationalAssortmentsOptions = nationalAssortments();
const tobaccoTypesOptions = tobaccoTypes();
const capabilitiesOptions = capabilities();
const customAssortmentsOptions = customAssortments();

const items = (props: O.Partial<Item, 'deep'>[] = []): Item[] =>
  mergeDeepArrays<Item>(
    replicateN({
      data: makeItem(),
      n: 5,
      replacements: {
        manufacturingType: [
          ,
          ...pickOptions({ options: manufacturingTypeOptions, pick: [0, 1, 0, 1] }),
        ],
        alcoholType: [, ...pickOptions({ options: alcoholTypesOptions, pick: [1, 2, 3, 4] })],
        nationalAssortment: [
          ,
          ...pickOptions({ options: nationalAssortmentsOptions, pick: [1, 2, 3, 0] }),
        ],
        tobaccoType: [, ...pickOptions({ options: tobaccoTypesOptions, pick: [1, 2, 3, 4] })],
        capabilities: [
          ,
          pickOptions({ options: capabilitiesOptions, pick: 1 }),
          pickOptions({ options: capabilitiesOptions, pick: 2 }),
          pickOptions({ options: capabilitiesOptions, pick: 3 }),
          pickOptions({ options: capabilitiesOptions, pick: 0 }),
        ],
        customAssortments: [
          ,
          pickOptions({ options: customAssortmentsOptions, pick: 1 }),
          pickOptions({ options: customAssortmentsOptions, pick: [3, 2] }),
          pickOptions({ options: customAssortmentsOptions, pick: [2] }),
          pickOptions({ options: customAssortmentsOptions, pick: [0, 1, 2, 3] }),
        ],
      },
      skipIncrementForFields: [
        'manufacturingType',
        'alcoholType',
        'nationalAssortment',
        'tobaccoType',
        'capabilities',
        'customAssortments',
        'percentageOverageAllowed',
        'sellableTemperature',
        'storageTemperature',
        'packingConstraint',
      ],
    }),
    props,
  );

export { items };
