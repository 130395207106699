import { mergeDeepRight } from 'ramda';
import { O } from 'ts-toolbelt';

import { Item, PackingConstraintType, TemperatureType } from 'shared/types';

import { makeAlcoholType } from './makeAlcoholType';
import { makeCapability } from './makeCapability';
import { makeCustomAssortment } from './makeCustomAssortment';
import { makeManufacturingType } from './makeManufacturingType';
import { makeNationalAssortment } from './makeNationalAssortment';
import { makeTobaccoType } from './makeTobaccoType';

const makeItem = (props: O.Partial<Item, 'deep'> = {}): Item =>
  mergeDeepRight<Item, O.Partial<Item, 'deep'>>(
    {
      id: '20687',
      name: 'Blackberry Organic Sparkling Yerb',
      upc: ['1234567895', '1234567856', '1234567858', '1234567978', '1234567845'],
      category: 'Alcohol',
      class: 'Coffee',
      brand: "M&M'S",
      department: 'Drink',
      parentCompany: 'Mars/Wrigley',
      isAlcohol: true,
      isTobacco: true,
      isBom: true,
      oracleSyncStatus: ['SYNCED'],
      customAssortments: [makeCustomAssortment()],
      capabilities: [makeCapability()],
      stopBuyingFlag: true,
      nationalAssortment: makeNationalAssortment(),
      alcoholType: makeAlcoholType(),
      alcoholByVolume: 1.1,
      tobaccoType: makeTobaccoType(),
      guaranteedShelfLife: 25,
      manufacturingType: makeManufacturingType(),
      extId: '1',
      family: '1',
      isDiscontinued: false,
      isConfirmed: true,
      pullByDate: 1,
      markdownDays: 2,
      __typename: 'Item',
      productType: '1',
      subClass: 'New',
      captureExpiry: true,
      acceptableShelfLife: 5,
      markdownItem: false,
      baggableAtPack: true,
      binnableAtPack: true,
      packingConstraint: PackingConstraintType.None,
      requireProduceBag: false,
      ignoreBlockOverReceipt: true,
      percentageOverageAllowed: 100,
      thawToSellEnabled: true,
      thawReplenishmentMinQty: 1,
      thawReplenishmentMaxQty: 4,
      thawTimeHours: 2,
      thawExpiryDays: 30,
      sellableTemperature: 'Ambient' as TemperatureType,
      storageTemperature: 'Ambient' as TemperatureType,
      i18n: {
        name: {
          deDE: '',
          enCA: '',
          enGB: '',
          enUS: '',
          esES: '',
          frCA: '',
          frFR: '',
        },
      },
      updatedAt: 0,
      updatedBy: 'test',
      itemCapabilityUpdatedAt: 0,
      itemCapabilityUpdatedBy: 'test',
      itemCustomAssortmentUpdatedAt: 0,
      itemCustomAssortmentUpdatedBy: 'test',
      iihCategoryName: '1',
      usCategoryName: '1',
    },
    props,
  ) as Item;

export { makeItem };
