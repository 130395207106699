import { UseRowStateLocalState } from 'react-table';

import { MappedRow } from 'pages/SourceDestination/types';
import {
  ContainerCapacityInput,
  ContainerUom,
  UpdateSourceDestinationMutationVariables,
} from 'shared/types';

const mapRowToMutationArgs = (
  row: MappedRow,
  updatedChunk: Record<string, unknown>,
  _: any,
  containerUom: ContainerUom[],
  state: UseRowStateLocalState<MappedRow>,
): UpdateSourceDestinationMutationVariables => {
  let newCapacities: ContainerCapacityInput[] = [];
  let mappedUpdateChunk = updatedChunk;
  const updatingCapacity =
    updatedChunk.containerMax !== undefined || updatedChunk.containerMin !== undefined;

  if (updatingCapacity) {
    let foundCapacity = false;

    row.capacities!.forEach(
      ({ containerMax, containerMin, containerUom: { id: containerUomId }, id }) => {
        let newContainerMin = containerMin;
        let newContainerMax = containerMax;

        if (containerUomId === state.containerUom) {
          foundCapacity = true;

          newContainerMin =
            updatedChunk.containerMin !== undefined
              ? (updatedChunk.containerMin as number)
              : containerMin;
          newContainerMax =
            updatedChunk.containerMax !== undefined
              ? (updatedChunk.containerMax as number)
              : containerMax;
        }

        newCapacities.push({
          id,
          containerMin: newContainerMin,
          containerMax: newContainerMax,
          containerUom: containerUomId,
        });
      },
    );

    if (!foundCapacity) {
      newCapacities.push({
        containerMin: (updatedChunk.containerMin as number) ?? 0,
        containerMax: (updatedChunk.containerMax as number) ?? 0,
        containerUom: state.containerUom
          ? (state.containerUom as string)
          : (containerUom.find(containerUomItem => containerUomItem.name === 'Weight')
              ?.id as string),
      });
    }

    mappedUpdateChunk = {};
  } else {
    newCapacities = row.capacities!.map(
      ({ containerMax, containerMin, containerUom: { id: containerUomId }, id }) => ({
        id,
        containerMin,
        containerMax,
        containerUom: containerUomId,
      }),
    );
  }

  return {
    input: {
      destinations: [
        {
          id: row.id,
          isActive: row.isActive,
          fillKillThreshold: row.fillKillThreshold,
          monLeadTime: row.monLeadTime,
          tueLeadTime: row.tueLeadTime,
          wedLeadTime: row.wedLeadTime,
          thuLeadTime: row.thuLeadTime,
          friLeadTime: row.friLeadTime,
          satLeadTime: row.satLeadTime,
          sunLeadTime: row.sunLeadTime,
          autoRelease: row.autoRelease,
          capacities: newCapacities,
          gopuffTransport: row.gopuffTransport,
          transitType: row.transitType,
          deliveryWindowFrom: row.deliveryWindowFrom,
          deliveryWindowTo: row.deliveryWindowTo,
          orderFrequency: row.orderFrequency,
          minimumOrderValue: row.minimumOrderValue,
          movCurrencyId: row.movCurrencyId,
          minimumOrderQuantity: row.minimumOrderQuantity,
          moqOrderingUomId: row.moqOrderingUomId,
          minimumOrderWeight: row.minimumOrderWeight,
          mowSystemOfMeasureId: row.mowSystemOfMeasureId,
          ...(updatingCapacity ? {} : mappedUpdateChunk),
        },
      ],
    },
  };
};

export { mapRowToMutationArgs };
