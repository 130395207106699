import { invertObj } from 'ramda';

import { PackageTypeName } from 'shared/constants/package';
import { MappedRow, Row } from 'pages/ItemSource/types';
import {
  BreakableColumnNames,
  PackageTypeColumnName,
  SupplierItemNumberColumnName,
} from 'pages/ItemSource/constants';
import {
  ItemSourcePackagingInput,
  PackageTypesQuery,
  UpdateItemSourceMutationVariables,
} from 'shared/types';

const packageTypeColumnNames = Object.values(PackageTypeColumnName);
const columnNamePackageTypeMap = invertObj(PackageTypeColumnName);

const mapRowToMutationArgs = (
  row: Row,
  updatedChunk: Record<string, any>,
  { packageTypeName }: MappedRow,
  { packageTypes }: Pick<PackageTypesQuery, 'packageTypes'>,
): UpdateItemSourceMutationVariables => {
  const newPackagings: ItemSourcePackagingInput[] = [];
  const updateColumnName = Object.keys(updatedChunk)[0];
  let updatedPackagingQuantity = false;
  let updated = false;

  row.packaging.forEach(
    ({
      __typename,
      declaredVolume,
      eachUPCs,
      id,
      measuredVolume,
      packageType: { id: packageTypeId, name },
      supplierNumber,
      upcCode,
      ...props
    }) => {
      const newPackaging: ItemSourcePackagingInput = {
        declaredHeight: props.declaredHeight,
        declaredLength: props.declaredLength,
        declaredWidth: props.declaredWidth,
        declaredLengthUnits: props.declaredLengthUnits,
        declaredWeight: props.declaredWeight,
        declaredWeightUnits: props.declaredWeightUnits,
        declaredTi: props.declaredTi,
        declaredHi: props.declaredHi,
        measuredHeight: props.measuredHeight,
        measuredLength: props.measuredLength,
        measuredWidth: props.measuredWidth,
        measuredLengthUnits: props.measuredLengthUnits,
        measuredWeight: props.measuredWeight,
        measuredWeightUnits: props.measuredWeightUnits,
        measuredTi: props.measuredTi,
        measuredHi: props.measuredHi,
        perChildPackageQty: props.perChildPackageQty,
        packageType: packageTypeId,
        upcCode: name === PackageTypeName.Each ? undefined : upcCode,
        supplierNumber,
        maxAllocationQuantity: props.maxAllocationQuantity,
        roundingThreshold: props.roundingThreshold,
      };
      const newPerChildPackageQty = updatedChunk[
        PackageTypeColumnName[name as Exclude<PackageTypeName, PackageTypeName.Each>]
      ] as number;

      if (newPerChildPackageQty !== undefined) {
        updatedPackagingQuantity = true;
        newPackaging.perChildPackageQty = newPerChildPackageQty;

        if (newPerChildPackageQty !== null) {
          newPackagings.push(newPackaging);
        }
      } else if (name === packageTypeName) {
        if (updateColumnName === 'upc') {
          newPackaging.upcCode = updatedChunk[updateColumnName] as string;
        } else if (updateColumnName === SupplierItemNumberColumnName) {
          newPackaging.supplierNumber = updatedChunk[updateColumnName] as string;
        } else if (
          !packageTypeColumnNames.includes(
            updateColumnName as typeof packageTypeColumnNames[number],
          ) &&
          !BreakableColumnNames.includes(updateColumnName)
        ) {
          newPackaging[updateColumnName as keyof ItemSourcePackagingInput] = updatedChunk[
            updateColumnName
          ] as never;
        }
        updated = true;
        newPackagings.push(newPackaging);
      } else {
        newPackagings.push(newPackaging);
      }
    },
  );

  if (
    !updatedPackagingQuantity &&
    packageTypeColumnNames.includes(updateColumnName as typeof packageTypeColumnNames[number])
  ) {
    const newPackaging: ItemSourcePackagingInput = {
      packageType: packageTypes.find(
        ({ name }) => name === columnNamePackageTypeMap[updateColumnName],
      )!.id,
      perChildPackageQty: updatedChunk[updateColumnName] as number,
    };
    newPackagings.push(newPackaging);
  } else if (!updated) {
    const newPackaging: ItemSourcePackagingInput = {
      packageType: packageTypes.find(({ name }) => name === PackageTypeName.Each)!.id,
    };
    if (updateColumnName === SupplierItemNumberColumnName) {
      newPackaging.supplierNumber = updatedChunk[updateColumnName] as string;
    } else if (updateColumnName === 'upc') {
      newPackaging.upcCode = updatedChunk[updateColumnName] as string;
    } else if (!BreakableColumnNames.includes(updateColumnName)) {
      newPackaging[updateColumnName as keyof ItemSourcePackagingInput] = updatedChunk[
        updateColumnName
      ] as never;
    }
    newPackagings.push(newPackaging);
  }

  const breakableFieldToUpdate: Record<string, any> = {};
  if (BreakableColumnNames.includes(updateColumnName)) {
    breakableFieldToUpdate[updateColumnName] = updatedChunk[updateColumnName];
  }

  return {
    input: {
      items: [
        {
          id: row.id,
          opcoID: row.opco.id,
          itemID: row.item.id,
          packaging: newPackagings,
          isBreakable: row.isBreakable,
          breakingFromPackageTypeID: row.breakingFromPackageTypeID,
          breakingToPackageTypeID: row.breakingToPackageTypeID,
          breakingThreshold: row.breakingThreshold,
          ...breakableFieldToUpdate,
        },
      ],
    },
  };
};

export { mapRowToMutationArgs };
