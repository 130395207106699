import { Option, PackingConstraintType, TemperatureType } from 'shared/types';

const temperatureOptions: Option<string, string>[] = [
  {
    label: 'Ambient',
    value: TemperatureType.Ambient,
  },
  {
    label: 'Cool',
    value: TemperatureType.Cool,
  },
  {
    label: 'Frozen',
    value: TemperatureType.Frozen,
  },
];

const packingConstraintOptions: Option<string, string>[] = [
  {
    label: 'Alone',
    value: PackingConstraintType.Alone,
  },
  {
    label: 'Same Department',
    value: PackingConstraintType.SameDepartment,
  },
  {
    label: 'Same Class',
    value: PackingConstraintType.SameClass,
  },
  {
    label: 'Same Subclass',
    value: PackingConstraintType.SameSubclass,
  },
  {
    label: 'None',
    value: PackingConstraintType.None,
  },
];

export { packingConstraintOptions, temperatureOptions };
