import { FC } from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';

import { externalRoles } from 'shared/constants/access';
import { useIsSystemLocked, usePermissions, useRole } from 'shared/hooks';
import { EnvironmentBanner } from 'shared/components/EnvironmentBanner';
import {
  PAGE_COST_MANAGEMENT_V2,
  PAGE_DEAL_MANAGEMENT,
  PAGE_RESOURCES,
} from 'shared/constants/access/pages';

import { UpdateAssortmentGlobalLoader } from '../Loader';
import { LanguageDropdown } from '../LanguageDropdown';

import { NestedList } from './components/NestedList';
import { ProfileLogout } from './components/ProfileLogout';
import styles from './Menu.module.css';
import { MenuProps } from './types';
import { ReactComponent as GopuffLogo } from './gopuff-logo.svg';

const Menu: FC<MenuProps> = ({ unprocessedRecordsCount }) => {
  const userPermissions = usePermissions();
  const role = useRole();
  const intl = useIntl();
  const { systemLocked } = useIsSystemLocked();
  let link;

  if (externalRoles.has(role)) {
    link = (
      <Link className={styles.link} to="/">
        <Typography
          align="left"
          className={styles.title}
          data-testid="gopuff-logo"
          fontWeight="bold"
          variant="subtitle1"
        >
          Vendor Management
        </Typography>
        <Typography align="right" className={styles.title} variant="h5">
          Powered by gopuff
        </Typography>
      </Link>
    );
  } else {
    link = (
      <Link to="/">
        <GopuffLogo className={styles.logo} data-testid="gopuff-logo" />
      </Link>
    );
  }

  return (
    <Drawer
      anchor="left"
      classes={{
        root: styles.drawer,
        paper: styles.paper,
      }}
      variant="permanent"
    >
      <Grid>
        {link}
        <List component="nav" disablePadding>
          <NestedList
            items={[
              {
                title: intl.formatMessage({
                  id: 'menu.title.baseData.item',
                  defaultMessage: 'Item',
                }),
                count: unprocessedRecordsCount?.itemsCount,
                to: '/item',
              },
              {
                title: intl.formatMessage({
                  id: 'menu.title.baseData.location',
                  defaultMessage: 'Location',
                }),
                count: unprocessedRecordsCount?.locationsCount,
                to: '/location',
              },
              {
                title: intl.formatMessage({
                  id: 'menu.title.baseData.suppliers',
                  defaultMessage: 'Suppliers',
                }),
                to: '/suppliers',
              },
            ]}
            title={intl.formatMessage({
              id: 'menu.title.baseData',
              defaultMessage: 'Base Data',
            })}
            userPermissions={userPermissions}
          />
          <NestedList
            items={[
              {
                title: intl.formatMessage({
                  id: 'menu.title.connections.supplierAssortment',
                  defaultMessage: 'Supplier Assortment',
                }),
                count: [
                  unprocessedRecordsCount?.supplierAssortmentItemsCount as number,
                  unprocessedRecordsCount?.supplierAssortmentsOpcosCount as number,
                ],
                to: '/supplier-assortment',
              },
              {
                title: intl.formatMessage({
                  id: 'menu.title.connections.transportationLane',
                  defaultMessage: 'Transportation Lane',
                }),
                count: [
                  unprocessedRecordsCount?.transportationLaneSourcesCount as number,
                  unprocessedRecordsCount?.transportationLaneDestinationsCount as number,
                ],
                to: '/transportation-lane',
              },
              {
                title: intl.formatMessage({
                  id: 'menu.title.connections.destinationAssortment',
                  defaultMessage: 'Destination Assortment',
                }),
                count: unprocessedRecordsCount?.destinationAssortmentsCount,
                to: '/destination-assortment',
              },
            ]}
            title={intl.formatMessage({
              id: 'menu.title.connections',
              defaultMessage: 'Connections',
            })}
            userPermissions={userPermissions}
          />
          <NestedList
            items={[
              {
                title: intl.formatMessage({
                  id: 'menu.title.costDealManagement.costManagement',
                  defaultMessage: 'Cost Management',
                }),
                to: '/cost-management',
              },
              {
                title: intl.formatMessage({
                  id: 'menu.title.costDealManagement.costManagementV2',
                  defaultMessage: 'Vendor Cost Management',
                }),
                to: PAGE_COST_MANAGEMENT_V2,
              },
              {
                title: intl.formatMessage({
                  id: 'menu.title.costDealManagement.dealManagement',
                  defaultMessage: 'Deal Management',
                }),
                to: PAGE_DEAL_MANAGEMENT,
              },
              {
                title: intl.formatMessage({
                  id: 'menu.title.costDealManagement.resources',
                  defaultMessage: 'Resources',
                }),
                to: PAGE_RESOURCES,
              },
            ]}
            title={intl.formatMessage({
              id: 'menu.title.costDealManagement',
              defaultMessage: 'Cost & Deal Management',
            })}
            userPermissions={userPermissions}
          />
          <NestedList
            items={[
              {
                title: intl.formatMessage({
                  id: 'menu.title.complementaryData.itemSource',
                  defaultMessage: 'Item Source',
                }),
                count: unprocessedRecordsCount?.itemSourcesCount,
                to: '/item-source',
              },
              {
                title: intl.formatMessage({
                  id: 'menu.title.complementaryData.sourceDestination',
                  defaultMessage: 'Source Destination',
                }),
                count: unprocessedRecordsCount?.sourceDestinationsCount,
                to: '/source-destination',
              },
              {
                title: intl.formatMessage({
                  id: 'menu.title.complementaryData.userDefinedAttribute',
                  defaultMessage: 'User-Defined Attribute',
                }),
                to: '/user-defined-attribute',
              },
            ]}
            title={intl.formatMessage({
              id: 'menu.title.complementaryData',
              defaultMessage: 'Complementary Data',
            })}
            userPermissions={userPermissions}
          />
        </List>
      </Grid>
      <div>
        {systemLocked && <UpdateAssortmentGlobalLoader />}
        <EnvironmentBanner />
        <LanguageDropdown />
        <ProfileLogout />
      </div>
    </Drawer>
  );
};

export { Menu };
