import { config } from '../../../setup';
import { FEATURE_FLAG_SHOW_DEAL_MANAGEMENT } from '../featureFlags';

import * as ACTIONS from './actions';
import * as ROLES from './roles';
import * as PAGES from './pages';

const {
  ACTION_EDIT_DESTINATION_ASSORTMENT_LOCAL_COST,
  ACTION_EDIT_DESTINATION_ASSORTMENT_LOCAL_COST_CURRENCY,
  ACTION_EDIT_DESTINATION_ASSORTMENT_NATIONAL_COST,
  ACTION_EDIT_DESTINATION_ASSORTMENT_NATIONAL_COST_CURRENCY,
  ACTION_EDIT_LOCATION,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_ALCOHOL_TYPE,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_CUSTOM_ASSORTMENT_CODE,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_LOCATION_ITEM_CAPABILITIES,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_NATIONAL_ASSORTMENT_CODE,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_ALLOCATION_PRIORITY,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_TOBACCO_TYPE,
  ACTION_MASS_DESTINATION_ASSORTMENT,
  ACTION_MASS_EDIT_ITEM,
  ACTION_MASS_EDIT_ITEM_SOURCE,
  ACTION_MASS_EDIT_LOCATION,
  ACTION_MASS_EDIT_SOURCE_DESTINATION,
  ACTION_MASS_EDIT_SUPPLIER_ASSORTMENT,
  ACTION_MASS_EDIT_TRANSPORTATION_LANE,
  ACTION_UPDATE_DESTINATION_ASSORTMENT,
  ACTION_UPLOAD_FILE_DESTINATION_ASSORTMENT,
  ACTION_UPLOAD_FILE_ITEM,
  ACTION_UPLOAD_FILE_ITEM_SOURCE,
  ACTION_UPLOAD_FILE_LOCATION,
  ACTION_UPLOAD_FILE_SOURCE_DESTINATION,
  ACTION_UPLOAD_FILE_SUPPLIER_ASSORTMENT,
  ACTION_UPLOAD_FILE_TRANSPORTATION_LANE,
  ACTION_VIEW_PAGE_ALL,
  ACTION_VIEW_PAGE_BOM,
  ACTION_VIEW_PAGE_COST_MANAGEMENT,
  ACTION_VIEW_PAGE_COST_MANAGEMENT_V2,
  ACTION_VIEW_PAGE_DEAL_MANAGEMENT,
  ACTION_VIEW_PAGE_DEAL_MANAGEMENT_DETAIL,
  ACTION_VIEW_PAGE_DESTINATION_ASSORTMENT,
  ACTION_VIEW_PAGE_ITEM,
  ACTION_VIEW_PAGE_ITEM_SOURCE,
  ACTION_VIEW_PAGE_LOCATION,
  ACTION_VIEW_PAGE_RESOURCES,
  ACTION_VIEW_PAGE_SOURCE_DESTINATION,
  ACTION_VIEW_PAGE_SUPPLIER_ASSORTMENT,
  ACTION_VIEW_PAGE_SUPPLIERS,
  ACTION_VIEW_PAGE_TRANSPORTATION_LANE,
  ACTION_VIEW_PAGE_USER_DEFINED_ATTRIBUTES,
} = ACTIONS;

const {
  ROLE_ADMIN,
  ROLE_ANALYST,
  ROLE_BUYER,
  ROLE_MANAGER,
  ROLE_MANUFACTURER,
  ROLE_PRICING,
  ROLE_READ_ONLY,
  ROLE_VENDOR,
} = ROLES;

const {
  PAGE_ALL,
  PAGE_BOM,
  PAGE_COST_MANAGEMENT,
  PAGE_COST_MANAGEMENT_V2,
  PAGE_DEAL_MANAGEMENT,
  PAGE_DEAL_MANAGEMENT_DETAIL,
  PAGE_DESTINATION_ASSORTMENT,
  PAGE_ITEM,
  PAGE_ITEM_SOURCE,
  PAGE_LOCATION,
  PAGE_RESOURCES,
  PAGE_SOURCE_DESTINATION,
  PAGE_SUPPLIER_ASSORTMENT,
  PAGE_SUPPLIERS,
  PAGE_TRANSPORTATION_LANE,
  PAGE_USER_DEFINED_ATTRIBUTES,
} = PAGES;

export type PagesType = keyof typeof PAGES;
export type ActionsType = keyof typeof ACTIONS;
export type RolesType = typeof ROLES[keyof typeof ROLES];
export type Permissions = Set<ActionsType>;
export interface UserRole {
  role: RolesType;
}

export const externalRoles = new Set<RolesType>([ROLE_VENDOR, ROLE_MANUFACTURER]);

export const pagePermissions: Map<string, ActionsType> = new Map([
  [PAGE_ALL, ACTION_VIEW_PAGE_ALL],
  [PAGE_ITEM, ACTION_VIEW_PAGE_ITEM],
  [PAGE_LOCATION, ACTION_VIEW_PAGE_LOCATION],
  [PAGE_SUPPLIERS, ACTION_VIEW_PAGE_SUPPLIERS],
  [PAGE_SUPPLIER_ASSORTMENT, ACTION_VIEW_PAGE_SUPPLIER_ASSORTMENT],
  [PAGE_SOURCE_DESTINATION, ACTION_VIEW_PAGE_SOURCE_DESTINATION],
  [PAGE_ITEM_SOURCE, ACTION_VIEW_PAGE_ITEM_SOURCE],
  [PAGE_DESTINATION_ASSORTMENT, ACTION_VIEW_PAGE_DESTINATION_ASSORTMENT],
  [PAGE_TRANSPORTATION_LANE, ACTION_VIEW_PAGE_TRANSPORTATION_LANE],
  [PAGE_BOM, ACTION_VIEW_PAGE_BOM],
  [PAGE_COST_MANAGEMENT, ACTION_VIEW_PAGE_COST_MANAGEMENT],
  [PAGE_COST_MANAGEMENT_V2, ACTION_VIEW_PAGE_COST_MANAGEMENT_V2],
  [PAGE_DEAL_MANAGEMENT, ACTION_VIEW_PAGE_DEAL_MANAGEMENT],
  [PAGE_DEAL_MANAGEMENT_DETAIL, ACTION_VIEW_PAGE_DEAL_MANAGEMENT_DETAIL],
  [PAGE_USER_DEFINED_ATTRIBUTES, ACTION_VIEW_PAGE_USER_DEFINED_ATTRIBUTES],
  [PAGE_RESOURCES, ACTION_VIEW_PAGE_RESOURCES],
]);

const readAndWritePermissions: ActionsType[] = [
  ACTION_EDIT_DESTINATION_ASSORTMENT_LOCAL_COST_CURRENCY,
  ACTION_EDIT_DESTINATION_ASSORTMENT_NATIONAL_COST_CURRENCY,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_NATIONAL_ASSORTMENT_CODE,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_ALLOCATION_PRIORITY,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_CUSTOM_ASSORTMENT_CODE,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_ALCOHOL_TYPE,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_TOBACCO_TYPE,
  ACTION_EDIT_USER_DEFINED_ATTRIBUTES_LOCATION_ITEM_CAPABILITIES,
  ACTION_UPLOAD_FILE_SUPPLIER_ASSORTMENT,
  ACTION_UPLOAD_FILE_SOURCE_DESTINATION,
  ACTION_UPLOAD_FILE_TRANSPORTATION_LANE,
  ACTION_UPLOAD_FILE_LOCATION,
  ACTION_UPLOAD_FILE_ITEM_SOURCE,
  ACTION_MASS_EDIT_ITEM,
  ACTION_MASS_EDIT_LOCATION,
  ACTION_EDIT_LOCATION,
  ACTION_MASS_EDIT_TRANSPORTATION_LANE,
  ACTION_MASS_EDIT_ITEM_SOURCE,
  ACTION_MASS_EDIT_SOURCE_DESTINATION,
  ACTION_MASS_EDIT_SUPPLIER_ASSORTMENT,
  ACTION_UPLOAD_FILE_DESTINATION_ASSORTMENT,
  ACTION_UPLOAD_FILE_ITEM,
];
const viewAllPagesPermissions: ActionsType[] = [
  ACTION_VIEW_PAGE_ALL,
  ACTION_VIEW_PAGE_BOM,
  ACTION_VIEW_PAGE_COST_MANAGEMENT,
  ACTION_VIEW_PAGE_COST_MANAGEMENT_V2,
  ACTION_VIEW_PAGE_DESTINATION_ASSORTMENT,
  ACTION_VIEW_PAGE_ITEM,
  ACTION_VIEW_PAGE_ITEM_SOURCE,
  ACTION_VIEW_PAGE_LOCATION,
  ACTION_VIEW_PAGE_SOURCE_DESTINATION,
  ACTION_VIEW_PAGE_SUPPLIER_ASSORTMENT,
  ACTION_VIEW_PAGE_SUPPLIERS,
  ACTION_VIEW_PAGE_TRANSPORTATION_LANE,
  ACTION_VIEW_PAGE_USER_DEFINED_ATTRIBUTES,
];

if (config.featureFlags[FEATURE_FLAG_SHOW_DEAL_MANAGEMENT]) {
  viewAllPagesPermissions.push(
    ACTION_VIEW_PAGE_DEAL_MANAGEMENT,
    ACTION_VIEW_PAGE_DEAL_MANAGEMENT_DETAIL,
  );
}

const adminPermissions = new Set<ActionsType>([
  ...readAndWritePermissions,
  ...viewAllPagesPermissions,
  ACTION_EDIT_DESTINATION_ASSORTMENT_LOCAL_COST,
  ACTION_EDIT_DESTINATION_ASSORTMENT_NATIONAL_COST,
  ACTION_MASS_DESTINATION_ASSORTMENT,
  ACTION_UPDATE_DESTINATION_ASSORTMENT,
]);
export const permissions: { [key in RolesType]: Permissions } = {
  [ROLE_ADMIN]: adminPermissions,
  [ROLE_MANAGER]: new Set<ActionsType>([
    ...viewAllPagesPermissions,
    ACTION_EDIT_USER_DEFINED_ATTRIBUTES_CUSTOM_ASSORTMENT_CODE,
    ACTION_EDIT_USER_DEFINED_ATTRIBUTES_ALCOHOL_TYPE,
    ACTION_EDIT_USER_DEFINED_ATTRIBUTES_TOBACCO_TYPE,
    ACTION_EDIT_USER_DEFINED_ATTRIBUTES_LOCATION_ITEM_CAPABILITIES,
    ACTION_EDIT_USER_DEFINED_ATTRIBUTES_ALLOCATION_PRIORITY,
    ACTION_UPLOAD_FILE_SUPPLIER_ASSORTMENT,
    ACTION_UPLOAD_FILE_SOURCE_DESTINATION,
    ACTION_UPLOAD_FILE_TRANSPORTATION_LANE,
    ACTION_UPLOAD_FILE_LOCATION,
    ACTION_UPLOAD_FILE_ITEM_SOURCE,
    ACTION_MASS_EDIT_ITEM,
    ACTION_MASS_EDIT_LOCATION,
    ACTION_EDIT_LOCATION,
    ACTION_MASS_EDIT_TRANSPORTATION_LANE,
    ACTION_MASS_EDIT_ITEM_SOURCE,
    ACTION_MASS_EDIT_SOURCE_DESTINATION,
    ACTION_MASS_EDIT_SUPPLIER_ASSORTMENT,
    ACTION_UPLOAD_FILE_DESTINATION_ASSORTMENT,
    ACTION_UPLOAD_FILE_ITEM,
  ]),
  [ROLE_PRICING]: new Set<ActionsType>([
    ...viewAllPagesPermissions,
    ACTION_EDIT_DESTINATION_ASSORTMENT_LOCAL_COST,
    ACTION_EDIT_DESTINATION_ASSORTMENT_LOCAL_COST_CURRENCY,
    ACTION_EDIT_DESTINATION_ASSORTMENT_NATIONAL_COST,
    ACTION_EDIT_DESTINATION_ASSORTMENT_NATIONAL_COST_CURRENCY,
    ACTION_UPLOAD_FILE_SUPPLIER_ASSORTMENT,
    ACTION_UPLOAD_FILE_SOURCE_DESTINATION,
    ACTION_UPLOAD_FILE_TRANSPORTATION_LANE,
    ACTION_UPLOAD_FILE_LOCATION,
    ACTION_UPLOAD_FILE_ITEM_SOURCE,
    ACTION_UPLOAD_FILE_DESTINATION_ASSORTMENT,
    ACTION_MASS_EDIT_ITEM,
    ACTION_MASS_DESTINATION_ASSORTMENT,
    ACTION_MASS_EDIT_LOCATION,
    ACTION_EDIT_LOCATION,
    ACTION_MASS_EDIT_TRANSPORTATION_LANE,
    ACTION_MASS_EDIT_ITEM_SOURCE,
    ACTION_MASS_EDIT_SOURCE_DESTINATION,
    ACTION_MASS_EDIT_SUPPLIER_ASSORTMENT,
    ACTION_UPLOAD_FILE_ITEM,
  ]),
  [ROLE_ANALYST]: new Set<ActionsType>([
    ...viewAllPagesPermissions,
    ACTION_UPLOAD_FILE_SUPPLIER_ASSORTMENT,
    ACTION_UPLOAD_FILE_SOURCE_DESTINATION,
    ACTION_UPLOAD_FILE_TRANSPORTATION_LANE,
    ACTION_UPLOAD_FILE_LOCATION,
    ACTION_UPLOAD_FILE_ITEM_SOURCE,
    ACTION_MASS_EDIT_ITEM,
    ACTION_MASS_EDIT_LOCATION,
    ACTION_MASS_EDIT_TRANSPORTATION_LANE,
    ACTION_MASS_EDIT_ITEM_SOURCE,
    ACTION_MASS_EDIT_SOURCE_DESTINATION,
    ACTION_MASS_EDIT_SUPPLIER_ASSORTMENT,
    ACTION_MASS_DESTINATION_ASSORTMENT,
    ACTION_UPLOAD_FILE_DESTINATION_ASSORTMENT,
    ACTION_UPLOAD_FILE_ITEM,
  ]),
  [ROLE_BUYER]: new Set<ActionsType>([
    ...viewAllPagesPermissions,
    ACTION_UPLOAD_FILE_SUPPLIER_ASSORTMENT,
    ACTION_UPLOAD_FILE_SOURCE_DESTINATION,
    ACTION_UPLOAD_FILE_TRANSPORTATION_LANE,
    ACTION_UPLOAD_FILE_LOCATION,
    ACTION_UPLOAD_FILE_ITEM_SOURCE,
    ACTION_UPLOAD_FILE_DESTINATION_ASSORTMENT,
    ACTION_MASS_EDIT_ITEM,
    ACTION_MASS_DESTINATION_ASSORTMENT,
    ACTION_MASS_EDIT_LOCATION,
    ACTION_EDIT_LOCATION,
    ACTION_MASS_EDIT_TRANSPORTATION_LANE,
    ACTION_MASS_EDIT_ITEM_SOURCE,
    ACTION_MASS_EDIT_SOURCE_DESTINATION,
    ACTION_MASS_EDIT_SUPPLIER_ASSORTMENT,
    ACTION_UPLOAD_FILE_ITEM,
  ]),
  [ROLE_READ_ONLY]: new Set<ActionsType>([...viewAllPagesPermissions]),
  [ROLE_VENDOR]: new Set<ActionsType>([
    ACTION_VIEW_PAGE_COST_MANAGEMENT_V2,
    ACTION_VIEW_PAGE_RESOURCES,
  ]),
  [ROLE_MANUFACTURER]: new Set<ActionsType>([
    ACTION_VIEW_PAGE_DEAL_MANAGEMENT,
    ACTION_VIEW_PAGE_DEAL_MANAGEMENT_DETAIL,
    ACTION_VIEW_PAGE_RESOURCES,
  ]),
};

if (config.featureFlags[FEATURE_FLAG_SHOW_DEAL_MANAGEMENT]) {
  permissions[ROLE_VENDOR].add(ACTION_VIEW_PAGE_DEAL_MANAGEMENT);
  permissions[ROLE_VENDOR].add(ACTION_VIEW_PAGE_DEAL_MANAGEMENT_DETAIL);
}

export const permissionsFeatureFlagOff: { [key in RolesType]: Permissions } = {
  [ROLE_ADMIN]: adminPermissions,
  [ROLE_MANAGER]: adminPermissions,
  [ROLE_PRICING]: adminPermissions,
  [ROLE_ANALYST]: adminPermissions,
  [ROLE_BUYER]: adminPermissions,
  [ROLE_VENDOR]: new Set<ActionsType>(readAndWritePermissions),
  [ROLE_MANUFACTURER]: new Set<ActionsType>(readAndWritePermissions),
  [ROLE_READ_ONLY]: new Set<ActionsType>(readAndWritePermissions),
};

const ROLE_INTERNAL_USER = new Set([
  ROLE_ADMIN,
  ROLE_ANALYST,
  ROLE_BUYER,
  ROLE_MANAGER,
  ROLE_PRICING,
  ROLE_READ_ONLY,
]);

// by default, all internal users have read only access. All other roles must be assigned.
const ROLE_ASSIGNED_INTERNAL_USER = new Set(
  Array.from(ROLE_INTERNAL_USER).filter(role => role !== ROLE_READ_ONLY),
);

const ROLE_EXTERNAL_USER = new Set([ROLE_VENDOR, ROLE_MANUFACTURER]);

export { ROLE_ASSIGNED_INTERNAL_USER, ROLE_INTERNAL_USER, ROLE_EXTERNAL_USER };
