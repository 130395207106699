import { isNil } from 'ramda';

import { UpdateDestinationAssortmentsMutationVariables } from 'shared/types';
import { CostFields, CurrencyFields, Row } from 'pages/DestinationAssortment/types';

const selectCost = (
  costFieldName: CostFields,
  currencyFieldName: CurrencyFields,
  updateChunck: Record<string, unknown>,
  row: Row,
): number | undefined => {
  const newCost = updateChunck[costFieldName] as number | undefined | null;
  const updatingCost = Object.hasOwnProperty.call(updateChunck, costFieldName);
  const currentCost = row[costFieldName];
  const currentCurrency = row[currencyFieldName];
  const nextCurrencyId = updateChunck[currencyFieldName];

  if (updatingCost) {
    return Number(newCost) ?? 0;
  }

  if ((currentCurrency?.id || nextCurrencyId) && isNil(currentCost)) {
    return 0;
  }

  return currentCost;
};

const mapRowToMutationArgs = (
  row: Row,
  updatedChuck: Record<string, unknown>,
): UpdateDestinationAssortmentsMutationVariables => {
  const mappedUpdatedChuck = updatedChuck;

  if (updatedChuck.selectedPackagingID) {
    mappedUpdatedChuck.packagingID = updatedChuck.selectedPackagingID;

    delete mappedUpdatedChuck.selectedPackagingID;
  }

  return {
    input: {
      assortments: [
        {
          id: row.id,
          maxAllocationQuantity: row.maxAllocationQuantity,
          assortmentOverrideState: row.assortmentOverrideState,
          packagingID: row.selectedPackagingID,
          localCostCurrency: row.localCostCurrency?.id,
          nationalCostCurrency: row.nationalCostCurrency?.id,
          velocityCategory: row.velocityCategory?.id,
          ...mappedUpdatedChuck,
          localCost: selectCost('localCost', 'localCostCurrency', mappedUpdatedChuck, row),
          nationalCost: selectCost('nationalCost', 'nationalCostCurrency', mappedUpdatedChuck, row),
        },
      ],
    },
  };
};

export { mapRowToMutationArgs };
