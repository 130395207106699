import { Row } from 'pages/Item/types';
import { mapToId } from 'shared/utils';

const mapRowToItemArgs = (row: Row) => ({
  id: row.id,
  capabilities: row.capabilities.map(mapToId),
  customAssortments: row.customAssortments.map(mapToId),
  stopBuyingFlag: row.stopBuyingFlag,
  nationalAssortment: row.nationalAssortment?.id,
  alcoholType: row.alcoholType?.id,
  alcoholByVolume: Number(row.alcoholByVolume),
  tobaccoType: row.tobaccoType?.id,
  guaranteedShelfLife: row.guaranteedShelfLife,
  ignoreBlockOverReceipt: row.ignoreBlockOverReceipt,
  manufacturingType: row.manufacturingType?.id,
  captureExpiry: row.captureExpiry,
  acceptableShelfLife: row.acceptableShelfLife,
  markdownItem: row.markdownItem,
  percentageOverageAllowed: row.percentageOverageAllowed,
  baggableAtPack: row.baggableAtPack,
  binnableAtPack: row.binnableAtPack,
  packingConstraint: row.packingConstraint,
  requireProduceBag: row.requireProduceBag,
  pullByDate: row.pullByDate,
  markdownDays: row.markdownDays,
  thawToSellEnabled: row.thawToSellEnabled,
  thawReplenishmentMinQty: row.thawReplenishmentMinQty,
  thawReplenishmentMaxQty: row.thawReplenishmentMaxQty,
  thawTimeHours: row.thawTimeHours,
  thawExpiryDays: row.thawExpiryDays,
  sellableTemperature: row.sellableTemperature,
  storageTemperature: row.storageTemperature,
});

export { mapRowToItemArgs };
